const allClasses = [
    {className: 'Hackathons 101: Everything You Need To Know', time: '10:00am-10:50am', room: 'Siebel 216', capacity: 93, description: 'No description.', classIndex: 0},
    {className: 'Python Games', time: '10:00am-10:50am', room: 'Siebel 218', capacity: 47, description: 'Students of all levels will dive into the world of game development by coding a classic game like Wordle, Hangman, Tic Tac Toe, or Connect 4. Whether you\'re a beginner or an experienced coder, you\'ll learn the fundamentals of Python programming while building a fun, interactive game. NO PYTHON EXPERIENCE REQUIRED - but AP CSA or CSP recommended. If you\'re well-versed in Python, we have stuff for you too!', classIndex: 1},
    {className: 'Motion Planning for Robotics and More', time: '10:00am-10:50am', room: 'Siebel 222', capacity: 48, description: 'Discover the core principles and current research that enable robots to navigate from point A to point B through complex environments. Develop your own algorithms for motion planning. Learn about other applications of motion planning: Google Maps, video games, and more!', classIndex: 3},
    {className: 'Cracking the Linux Kernel', time: '10:00am-10:50am', room: 'Siebel 1302', capacity: 48, description: '100% of UIUC students crash out when taking computer systems engineering! Experience those sleep-deprived, desperate, caffeine-fueled, delirious 5am-at-siebel nights with us as we dive into computer architecture and operating system fundamentals!', classIndex: 4},
    {className: 'Intro to Python', time: '10:00am-10:50am', room: 'Siebel 1304', capacity: 46, description: 'Curious about coding in python but don\'t know where to start? In just one hour, we\'ll learn the basics of Python—one of the most popular and beginner-friendly programming languages! We\'ll cover essential concepts like variables, loops, and functions through fun, hands-on exercises. No prior programming experience needed—just bring your curiosity and a laptop to begin your Python journey!', classIndex: 5},
    
    {className: 'How to Make a Python-powered Website', time: '10:00am-10:50am', room: 'Siebel 2102', capacity: 14, description: 'In \"How to Make Python-Powered Websites,\" you\'l learn how to do just that! We\'ll be walking through a live demo where you\'ll get hands-on experience with creating webpages, routing between them, and visualizing your Python code\'s output. Make sure to bring a computer with Python already installed to follow along!', classIndex: 7},
    {className: 'Recommendation System using Python', time: '10:00am-10:50am', room: 'Siebel 2124', capacity: 20, description: 'Cover Data Structures and Fundamental Ideas of using an Input based recommendation System in Python.', classIndex: 8},
    {className: 'Recruiting 101: How to Get a Job/Internship', time: '10:00am-10:50am', room: 'Siebel 2405', capacity: 70, description: 'Taught by 3 FAANG engineers with a combined 30+ offers, you\'ll learn about building your portfolio, acing interviews, and getting offers. Come set yourself up for career success!', classIndex: 9},
    {className: 'Live Coding Music', time: '10:00am-10:50am', room: 'Siebel 2407', capacity: 20, description: 'Learn how to make a song with Python using TunePad! Students will learn about music technology basics such as sampling and using bandpass and ASDR filters, as well as learn basic programming constructs and apply them to their music. Beginners in music and programming can remix a provided song or arrange their own song using provided live loops, and more advanced students can try tackling a composition from scratch!', classIndex: 10},
    {className: 'Discord Bot with Python', time: '10:00am-10:50am', room: 'Siebel 3102', capacity: 14, description: 'Learn how to create a simple discord bot with python that will answer questions with Ollama or ChatGPT. Basic coding knowledge preferred.', classIndex: 11},
    {className: 'Thinking Abstractly', time: '10:00am-10:50am', room: 'Siebel 3124', capacity: 20, description: 'The world changed with the internet, and it\'s changing again with AI. But what comes next? This interactive lesson explores the nature of artificial intelligence, its fundamental limitations, and the role of abstract thinking in navigating an AI-driven world. Through the lens of philosophy, computer science, and mathematics, we\'ll examine how inexpressibility—what cannot be fully captured in words, logic, or code—manifests across disciplines, from mathematics to machine learning. By engaging in deep discussions and thought experiments, students will develop the reasoning skills essential for computer scientists to tackle the unknown frontiers of technology.', classIndex: 12},
    {className: 'Optimization Algorithms', time: '10:00am-10:50am', room: 'Siebel 3401', capacity: 22, description: 'Explore foundational optimization algorithms like gradient descent and their probabilistic extensions, crucial tools behind modern machine learning. We\'ll dive into how probability and mathematical theory drive these algorithms toward smarter, faster solutions in computation and ML. Perfect for students passionate about mathematics, probability, and theoretical computer science.', classIndex: 13},
    {className: 'Hacking Language Models', time: '10:00am-10:50am', room: 'Siebel 4102', capacity: 14, description: 'How to make LLMs say things they\'re not supposed to? How to keep them from revealing what they shouldn\'t? You will learn to craft prompts that bypass safety guardrails, build automated attacks with Python, understand how training data can be poisoned, and discover the techniques that keep AI systems secure. Prereq: can code in Python.', classIndex: 14},
    {className: 'Binary and Digital Logic: What Does a Computer Really Do?', time: '10:00am-10:50am', room: 'Siebel 4407', capacity: 20, description: 'Learn the basics of computer architecture including how computers store and manipulate different types of data in binary as well as what happens when this goes wrong in the real world! Also, did you know that past innovations at UIUC made every modern computer possible?', classIndex: 15},
    
    
    {className: 'Intro to Deep Learning', time: '11:00am-11:50am', room: 'Siebel 216', capacity: 93, description: 'Learn the fundamentals of deep learning and machine learning while building a neural network to classify handwritten digits. This course provides a strong foundation in AI, helping you understand how machines recognize patterns and make decisions. You can become smarter than ChatGPT if u take this course.', classIndex: 16},
    {className: 'How to Make Your Own App', time: '11:00am-11:50am', room: 'Siebel 218', capacity: 47, description: 'Discover what it takes to make an app through engaging discuss and team bonding. You will learn what it takes to make an app and what apps already exist. Be ready to have your creative cap on!!!!', classIndex: 17},
    {className: 'Forensic Files: The Coding Edition', time: '11:00am-11:50am', room: 'Siebel 220', capacity: 48, description: 'Interested in true crime? Forensics? How computer science can help catch criminals from behind the scenes? In this class, you\'ll learn the ways in which real programs are used to analyze and identify DNA. The course will teach you a lot about how the methods and functions that can be used in computer forensics. By the end, you\'ll have a strong understanding of how to leverage programming to help solve crime. All you need is some basic programming knowledge!', classIndex: 18},
    {className: 'Motion Planning for Robotics and More', time: '11:00am-11:50am', room: 'Siebel 222', capacity: 48, description: 'Discover the core principles and current research that enable robots to navigate from point A to point B through complex environments. Develop your own algorithms for motion planning. Learn about other applications of motion planning: Google Maps, video games, and more!', classIndex: 19},
    {className: 'Cracking the Linux Kernel', time: '11:00am-11:50am', room: 'Siebel 1302', capacity: 48, description: '100% of UIUC students crash out when taking computer systems engineering! Experience those sleep-deprived, desperate, caffeine-fueled, delirious 5am-at-siebel nights with us as we dive into computer architecture and operating system fundamentals!', classIndex: 20},
    {className: 'Overcoming Imposter Syndrome in Tech', time: '11:00am-11:50am', room: 'Siebel 1304', capacity: 46, description: 'An interactive course that examines the causes of self-doubt in tech and teaches strategies to manage it. Through discussions and speaker insights, students will learn practical ways to build confidence and navigate academic challenges.', classIndex: 21},
    {className: 'Software to Silicon: How Your Code Actually Works', time: '11:00am-11:50am', room: 'Siebel 1404', capacity: 200, description: 'Explore the journey of code from high-level languages down to the physics of computation. You\'ll learn the basics of compilers, assembly, microarchitecture, and transistors, gaining a deeper appreciation for the hidden complexity behind every program you write. Whether you\'re a CS or engineering student, this course will give you the foundational knowledge to write better code and understand the machines running it.', classIndex: 22},
    {className: 'Build Your Own Keylogger', time: '11:00am-11:50am', room: 'Siebel 2102', capacity: 14, description: 'Learn about cybersecurity, spyware, and keyloggers, which are programs designed to monitor and record a user\'s keystrokes. In this hands-on class, we will also build a keylogger using Python. Some programming experience is recommended.', classIndex: 23},
    {className: 'Logic & Math for CS', time: '11:00am-11:50am', room: 'Siebel 2124', capacity: 20, description: 'In this course, you\'ll learn how to solve complex problems and think like a Computer Scientist! Via engaging activities, puzzles, and real-world applications, you\'ll learn how discrete math shapes everything from algorithms to social networks!', classIndex: 24},
    {className: 'Programming Killer AI Bots', time: '11:00am-11:50am', room: 'Siebel 2405', capacity: 70, description: 'Have you ever been curious to learn more about Artificial Intelligence (AI)? Now is your chance! Learn how to program a computer to play your favorite games intelligently. After this course, you will have a solid foundation for building smart bots that can beat your friends in various games.', classIndex: 25},
    {className: 'Landing Your First CS Internship: The Freshman Playbook', time: '11:00am-11:50am', room: 'Siebel 2407', capacity: 20, description: 'Starting your CS career early can feel daunting, but with the right approach, it\'s entirely within reach. This lesson will serve as your survival guide to navigating your first recruitment season, covering everything from building a standout resume to networking like a pro and securing the best opportunities for freshmen.', classIndex: 26},
    {className: 'Discord Chatbot 101', time: '11:00am-11:50am', room: 'Siebel 3102', capacity: 14, description: 'Ready to unleash your creativity and build a Discord bot that chats back? In this class, we\'ll walk you through using Repl.it and Python to build a Discord bot that can join your servers and talk with you in DMs.  Learn about tokens, API, and get creative programming the bot\'s responses! No download or setup requried!', classIndex: 27},
    {className: 'Cybersecurity Foundations: Learning Through Capture The Flag Challenges', time: '11:00am-11:50am', room: 'Siebel 3124', capacity: 20, description: 'An introductory course where students learn key cybersecurity concepts by solving hands-on CTF challenges. The course covers topics like encryption, network security, and vulnerability analysis, offering a fun and interactive way to build practical skills in ethical hacking and cybersecurity.', classIndex: 28},
    {className: 'Randomized Algorithms', time: '11:00am-11:50am', room: 'Siebel 3401', capacity: 22, description: 'Discover how randomness can simplify complex computational problems by cleverly introducing probability into algorithm design. We\'ll explore how randomized algorithms achieve impressive results—often faster and simpler than their deterministic counterparts—and analyze their behavior using probabilistic techniques. Designed for students interested in theoretical computer science, mathematics, and probability.', classIndex: 29},
    {className: 'Walkthrough of CS-Major at UIUC', time: '11:00am-11:50am', room: 'Siebel 4102', capacity: 14, description: 'In this class, you’ll get an understanding of how the CS major at UIUC plays out during your time in college. We’ll be exploring core classes, work opportunities, technical electives, and everything else that the CS program here has to offer you. Be sure to come with any questions you have about CS @ UIUC or the university in general!', classIndex: 30},
    {className: 'Behind the Scenes of a Freshman in CS', time: '11:00am-11:50am', room: 'Siebel 4407', capacity: 20, description: 'Are you an incoming freshmen or a prospective student? Join us to gain a better understanding of what it\'s like to be a freshman at UIUC, and more specifically, a freshman in CS! We\'ll give you general tips on things like dorm halls and the best study spots on campus, then also give you advice about CS RSOs, classes, proficiency exams, and more!', classIndex: 31},
    
    
    {className: 'Intro to Machine Learning - Neural Networks', time: '2:00pm-2:50pm', room: 'Siebel 216', capacity: 93, description: 'Learn about the history, applications, and relevance of neural networks and deep learning! We\'ll also briefly talk about the structure of neural networks and what makes them so powerful. At the end, you\'ll have the opportunity to build your own neural network and see it in action!', classIndex: 32},
    {className: 'Beadsort (learn sorting algorithms hands-on)', time: '2:00pm-2:50pm', room: 'Siebel 218', capacity: 47, description: 'No description.', classIndex: 33},
    {className: 'Looking Forward, Planning Ahead', time: '2:00pm-2:50pm', room: 'Siebel 220', capacity: 48, description: 'We want to talk today about some of the co-curricular or non-academic experiences you can explore to develop personally and professionally during your time at UIUC. Making the most of your time may require that you set goals and make plans. As such, we talk about the importance of planning ahead and setting goals to make the most of your time at college, and how to go about making such plans!', classIndex: 34},
    {className: 'Motion Planning for Robotics and More', time: '2:00pm-2:50pm', room: 'Siebel 222', capacity: 48, description: 'Discover the core principles and current research that enable robots to navigate from point A to point B through complex environments. Develop your own algorithms for motion planning. Learn about other applications of motion planning: Google Maps, video games, and more!', classIndex: 35},
    {className: 'Cracking the Linux Kernel', time: '2:00pm-2:50pm', room: 'Siebel 1302', capacity: 48, description: '100% of UIUC students crash out when taking computer systems engineering! Experience those sleep-deprived, desperate, caffeine-fueled, delirious 5am-at-siebel nights with us as we dive into computer architecture and operating system fundamentals!', classIndex: 36},
    {className: 'No B.S. College Advice for Ambitious High School Students', time: '2:00pm-2:50pm', room: 'Siebel 1304', capacity: 46, description: 'College advice comes from everywhere, from Reddit, YouTube videos, or unsolicited from your uncle Gary. In "No B.S. College Advice for Ambitious High School Students", we will cut through the noise and give uncommon, high quality advice that is tailor for high school students that have drive, ambition, and want to make the most out of their college experience.', classIndex: 37},
    {className: 'Build Your Own AI', time: '2:00pm-2:50pm', room: 'Siebel 1404', capacity: 200, description: 'How do self-driving cars work? How is ChatGPT so smart? Learn the answers to these questions by building your own computer vision neural network and diving into the applications of AI! No prior coding experience is necessary!', classIndex: 38},
    {className: 'Secrets Without Sharing: A Hands-On Intro to Zero-Knowledge Proofs', time: '2:00pm-2:50pm', room: 'Siebel 2102', capacity: 14, description: 'How can you prove you know a secret without revealing anything about it? Discover the mathematical magic of zero-knowledge proofs in this interactive workshop. We will learn to convince someone we have a solution without giving any clues and  connect these concepts to cryptocurrency, authentication, and blockchain, with a focus on the mathematical theory rather than complex coding. Prereq: basic algebra skills.', classIndex: 39},
    {className: 'Creating a website with GitHub pages and Jekyll', time: '2:00pm-2:50pm', room: 'Siebel 2124', capacity: 20, description: 'Creating a Website using GitHub pages and Jekyll: A hands-on beginner friendly session to help you get started with creating and publishing a website for free using GitHub pages. GitHub pages is an ideal solution for creating a simple project, a blog, or a personal site to highlight your academic work. Along the way we will cover the basics of GitHub, HTML, Markdown, and Jekyll.', classIndex: 40},
    {className: 'Inside the Mind of a Hacker', time: '2:00pm-2:50pm', room: 'Siebel 2405', capacity: 70, description: 'Are you interested in hacking? Do you see news stories about the latest breach and wonder how they did it? In this class, we\'ll introduce you to the world of cybersecurity, explore the adversarial mindset through some activities, and discuss different career paths in the field. By the end, you\'ll have a clearer picture of how hackers think, how professionals defend against them, and next steps for continuing your hacking journey!', classIndex: 61},
    {className: 'What\'s the Plot?', time: '2:00pm-2:50pm', room: 'Siebel 2407', capacity: 20, description: 'Lights, camera… data! This beginner-friendly course is your ticket to the world of data science, using movie data to make learning both engaging and practical. With Python, NumPy, and Matplotlib, you\'ll analyze popularity trends, explore genre patterns, and visualize audience ratings—all while building a strong foundation in data analysis. By the final scene, you\'ll be equipped with essential skills to uncover insights from data and tell compelling stories with numbers. No prior experience needed—just bring your curiosity!', classIndex: 62},
    {className: 'Introduction to Computer Architecture', time: '2:00pm-2:50pm', room: 'Siebel 3102', capacity: 14, description: 'Discover the fundamentals of computer architecture through an interactive deep dive into flip-flops, finite state machines, arithmetic machine dataflow, MIPS Assembly, and Verilog. See how these concepts shape real-world systems, from processors to embedded devices!', classIndex: 63},
    {className: 'Blockchain and Bitcoin 101', time: '2:00pm-2:50pm', room: 'Siebel 3124', capacity: 20, description: 'Want to know more about ₿lockchain and ₿itcoin? In this session, we\'ll break down the fundamentals of blockchain, how it works, and why it\'s revolutionizing today\'s industries from finance to healthcare. We\'ll also dive into Bitcoin, exploring why it stands out among cryptocurrencies and how it functions beyond traditional financial systems. By the end of this class, you\'ll gain a clear understanding of blockchain and bitcoin. This session will equip you with the insights to transform abstract principles to practical knowledge.', classIndex: 64},
    {className: 'Combinatorial Algorithms', time: '2:00pm-2:50pm', room: 'Siebel 3401', capacity: 22, description: 'Discover how counting, arrangements, and creative problem-solving underpin powerful algorithmic solutions. We\'ll explore combinatorial thinking to solve classic computer science puzzles—from graph searches and network flows to matching and optimization problems—learning to analyze their efficiency using mathematical reasoning. Ideal for students who enjoy puzzles and mathematics.', classIndex: 65},
    {className: 'Intro to Game Development in Roblox Studio', time: '2:00pm-2:50pm', room: 'Siebel 4102', capacity: 14, description: 'Welcome to Intro to Game Development! In this course, we\'ll be going over the ins and outs of developing a game using the Roblox Studio game engine. Knowledge of Roblox Studio is not required and the course will focus more on general concepts that can apply to any game engine whether it be Unity, Unreal or Godot. By the end of the class, you will be able to enjoy your own fully working experience. Assumes basic programming knowledge.', classIndex: 66},
    

    // {className: 'Intro to Data Structures', time: '12:30pm-1:20pm', room: 'Zoom', capacity: 30, description: 'Discover the building blocks of computer science through hands-on projects and engaging examples. This course introduces high school students to essential data structures like arrays, linked lists, and trees, along with fundamental algorithms that lay the groundwork for advanced programming.', classIndex: 41, zoomLink: "https://illinois.zoom.us/j/85856798530?pwd=bvDIBemDdvOTjkhODBBqvkUurpfyHJ.1"},
    // {className: 'Motion Planning for Robotics and More', time: '12:30pm-1:20pm', room: 'Zoom', capacity: 30, description: 'Discover the core principles and current research that enable robots to navigate from point A to point B through complex environments. Develop your own algorithms for motion planning. Learn about other applications of motion planning: Google Maps, video games, and more!', classIndex: 42, zoomLink: "https://illinois.zoom.us/j/2288593559?pwd=YWlTR0E4NXExUllzUmt0eWwxdGJWZz09"},
    // {className: 'Intro to Python', time: '12:30pm-1:20pm', room: 'Zoom', capacity: 40, description: 'Curious about coding in python but don\'t know where to start? In just one hour, we\'ll learn the basics of Python—one of the most popular and beginner-friendly programming languages! We\'ll cover essential concepts like variables, loops, and functions through fun, hands-on exercises. No prior programming experience needed—just bring your curiosity and a laptop to begin your Python journey!', classIndex: 43},
    // {className: 'Programming Killer AI Bots', time: '12:30pm-1:20pm', room: 'Zoom', capacity: 40, description: 'Have you ever been curious to learn more about Artificial Intelligence (AI)? Now is your chance! Learn how to program a computer to play your favorite games intelligently. After this course, you will have a solid foundation for building smart bots that can beat your friends in various games.', classIndex: 44, zoomLink: "https://illinois.zoom.us/j/84202817959?pwd=xklVLXmJM2t7nIlmpp88SiAk2aiakQ.1"},
    // {className: 'Overcoming Imposter Syndrome in Tech', time: '12:30pm-1:20pm', room: 'Zoom', capacity: 30, description: 'An interactive course that examines the causes of self-doubt in tech and teaches strategies to manage it. Through discussions and speaker insights, students will learn practical ways to build confidence and navigate academic challenges.', classIndex: 45, zoomLink: "https://illinois.zoom.us/j/83956963698?pwd=klt6bC8fyaMIgbLpU4RuswnBfpMHjr.1"},
    // {className: 'Intro to Machine Learning - Neural Networks', time: '12:30pm-1:20pm', room: 'Zoom', capacity: 40, description: 'Learn about the history, applications, and relevance of neural networks and deep learning! We\'ll also briefly talk about the structure of neural networks and what makes them so powerful. At the end, you\'ll have the opportunity to build your own neural network and see it in action!', classIndex: 46, zoomLink: "https://illinois.zoom.us/j/87333957846?pwd=WQSKCai28eyauCB5a05NVKB2PiOBPn.1"},
    // {className: 'Build Your Own AI', time: '12:30pm-1:20pm', room: 'Zoom', capacity: 40, description: 'How do self-driving cars work? How is ChatGPT so smart? Learn the answers to these questions by building your own computer vision neural network and diving into the applications of AI! No prior coding experience is necessary!', classIndex: 47, zoomLink: "https://illinois.zoom.us/j/89380161599?pwd=3IsShXr92nsC3IycuezAuj5q13yiUc.1"},
    // {className: 'Hackathons 101: Everything You Need To Know', time: '12:30pm-1:20pm', room: 'Zoom', capacity: 40, description: 'Description coming soon!', classIndex: 48},
    // {className: 'Inside the Mind of a Hacker', time: '12:30pm-1:20pm', room: 'Zoom', capacity: 40, description: 'Are you interested in hacking? Do you see news stories about the latest breach and wonder how they did it? In this class, we\'ll introduce you to the world of cybersecurity, explore the adversarial mindset through some activities, and discuss different career paths in the field. By the end, you\'ll have a clearer picture of how hackers think, how professionals defend against them, and next steps for continuing your hacking journey!', classIndex: 49, zoomLink: "https://illinois.zoom.us/j/89548576832?pwd=URsbNTe2fu0OzPzeoZeUESUur3bN0f.1"},
    // {className: 'Creating a website with GitHub pages and Jekyll', time: '12:30pm-1:20pm', room: 'Zoom', capacity: 20, description: 'Creating a Website using GitHub pages and Jekyll: A hands-on beginner friendly session to help you get started with creating and publishing a website for free using GitHub pages. GitHub pages is an ideal solution for creating a simple project, a blog, or a personal site to highlight your academic work. Along the way we will cover the basics of GitHub, HTML, Markdown, and Jekyll.', classIndex: 50, zoomLink: "https://illinois.zoom.us/j/85160290537?pwd=jVxAyglGii6a8kcZjuCTWOMScmdqDl.1"},
    
    
    // {className: 'Careers in CS', time: '1:30pm-2:20pm', room: 'Zoom', capacity: 30, description: 'Explore the diverse and exciting opportunities within the tech industry. In this course, students will learn about various computer science careers—from software engineering and data science to cybersecurity and game development—and gain insights into the skills and education needed to thrive in each field.', classIndex: 51, zoomLink: "https://illinois.zoom.us/j/83986007806?pwd=1EsuyfHQb373YQW9pu2Gera7vyi6G5.1"},
    // {className: 'Motion Planning for Robotics and More', time: '1:30pm-2:20pm', room: 'Zoom', capacity: 30, description: 'Discover the core principles and current research that enable robots to navigate from point A to point B through complex environments. Develop your own algorithms for motion planning. Learn about other applications of motion planning: Google Maps, video games, and more!', classIndex: 52, zoomLink: "https://illinois.zoom.us/j/2288593559?pwd=YWlTR0E4NXExUllzUmt0eWwxdGJWZz09"},
    // {className: 'Python, Pandas, and Predictions', time: '1:30pm-2:20pm', room: 'Zoom', capacity: 25, description: 'Dive into the world of data science at UIUC by exploring Python, Pandas, and AI/ML through real-time coding in Google Colab. Analyze real datasets and even build a machine learning model. No prior programming experience or software installation required :)', classIndex: 53, zoomLink: "https://illinois.zoom.us/j/82951595817?pwd=qlefT48s29y633ojmxb6rAaOaprYGC.1"},
    // {className: 'Build Your Own Keylogger', time: '1:30pm-2:20pm', room: 'Zoom', capacity: 30, description: 'Learn about cybersecurity, spyware, and keyloggers, which are programs designed to monitor and record a user\'s keystrokes. In this hands-on class, we will also build a keylogger using Python. Some programming experience is recommended.', classIndex: 54, zoomLink: "https://illinois.zoom.us/j/87441628105?pwd=9gAhN4JqBJoMVEe3va8gDtncvFwTlo.1"},
    // {className: 'Looking Forward, Planning Ahead', time: '1:30pm-2:20pm', room: 'Zoom', capacity: 40, description: 'We want to talk today about some of the co-curricular or non-academic experiences you can explore to develop personally and professionally during your time at UIUC. Making the most of your time may require that you set goals and make plans. As such, we talk about the importance of planning ahead and setting goals to make the most of your time at college, and how to go about making such plans!', classIndex: 56, zoomLink: "https://illinois.zoom.us/j/82163364824?pwd=LqFPWOqSHX4aHweqjW8cede9vOF0oq.1"},
    // {className: 'Software to Silicon: How Your Code Actually Works', time: '1:30pm-2:20pm', room: 'Zoom', capacity: 40, description: 'Explore the journey of code from high-level languages down to the physics of computation. You\'ll learn the basics of compilers, assembly, microarchitecture, and transistors, gaining a deeper appreciation for the hidden complexity behind every program you write. Whether you\'re a CS or engineering student, this course will give you the foundational knowledge to write better code and understand the machines running it.', classIndex: 57, zoomLink: "https://illinois.zoom.us/j/82789553793?pwd=CXbbfJYsLilPEWzJx4fm7tlp7uvu9s.1"},
    // {className: 'What\'s the Plot?', time: '1:30pm-2:20pm', room: 'Zoom', capacity: 20, description: 'Lights, camera… data! This beginner-friendly course is your ticket to the world of data science, using movie data to make learning both engaging and practical. With Python, NumPy, and Matplotlib, you\'ll analyze popularity trends, explore genre patterns, and visualize audience ratings—all while building a strong foundation in data analysis. By the final scene, you\'ll be equipped with essential skills to uncover insights from data and tell compelling stories with numbers. No prior experience needed—just bring your curiosity!', classIndex: 58, zoomLink: "https://us04web.zoom.us/j/73465555988?pwd=5LRxERQXlUL099vP8MZYy2TVxvdcja.1"},
    // {className: 'Intro to Game Development in Roblox Studio', time: '1:30pm-2:20pm', room: 'Zoom', capacity: 40, description: 'Welcome to Intro to Game Development! In this course, we\'ll be going over the ins and outs of developing a game using the Roblox Studio game engine. Knowledge of Roblox Studio is not required and the course will focus more on general concepts that can apply to any game engine whether it be Unity, Unreal or Godot. By the end of the class, you will be able to enjoy your own fully working experience. Assumes basic programming knowledge.', classIndex: 59, zoomLink: "https://illinois.zoom.us/j/3679837562?pwd=WpArGYv9KhwniLgG1vVZgfHOan1Z3x"},
    // {className: 'Introduction to Computer Architecture', time: '1:30pm-2:20pm', room: 'Zoom', capacity: 40, description: 'Discover the fundamentals of computer architecture through an interactive deep dive into flip-flops, finite state machines, arithmetic machine dataflow, MIPS Assembly, and Verilog. See how these concepts shape real-world systems, from processors to embedded devices!', classIndex: 60}


    {className: 'Intro to Data Structures', time: '12:30pm-1:20pm', room: 'Zoom', capacity: 30, description: 'Discover the building blocks of computer science through hands-on projects and engaging examples. This course introduces high school students to essential data structures like arrays, linked lists, and trees, along with fundamental algorithms that lay the groundwork for advanced programming.', classIndex: 41},
    {className: 'Motion Planning for Robotics and More', time: '12:30pm-1:20pm', room: 'Zoom', capacity: 30, description: 'Discover the core principles and current research that enable robots to navigate from point A to point B through complex environments. Develop your own algorithms for motion planning. Learn about other applications of motion planning: Google Maps, video games, and more!', classIndex: 42},
    {className: 'Intro to Python', time: '12:30pm-1:20pm', room: 'Zoom', capacity: 40, description: 'Curious about coding in python but don\'t know where to start? In just one hour, we\'ll learn the basics of Python—one of the most popular and beginner-friendly programming languages! We\'ll cover essential concepts like variables, loops, and functions through fun, hands-on exercises. No prior programming experience needed—just bring your curiosity and a laptop to begin your Python journey!', classIndex: 43},
    {className: 'Programming Killer AI Bots', time: '12:30pm-1:20pm', room: 'Zoom', capacity: 40, description: 'Have you ever been curious to learn more about Artificial Intelligence (AI)? Now is your chance! Learn how to program a computer to play your favorite games intelligently. After this course, you will have a solid foundation for building smart bots that can beat your friends in various games.', classIndex: 44},
    {className: 'Overcoming Imposter Syndrome in Tech', time: '12:30pm-1:20pm', room: 'Zoom', capacity: 30, description: 'An interactive course that examines the causes of self-doubt in tech and teaches strategies to manage it. Through discussions and speaker insights, students will learn practical ways to build confidence and navigate academic challenges.', classIndex: 45},
    {className: 'Intro to Machine Learning - Neural Networks', time: '12:30pm-1:20pm', room: 'Zoom', capacity: 40, description: 'Learn about the history, applications, and relevance of neural networks and deep learning! We\'ll also briefly talk about the structure of neural networks and what makes them so powerful. At the end, you\'ll have the opportunity to build your own neural network and see it in action!', classIndex: 46},
    {className: 'Build Your Own AI', time: '12:30pm-1:20pm', room: 'Zoom', capacity: 40, description: 'How do self-driving cars work? How is ChatGPT so smart? Learn the answers to these questions by building your own computer vision neural network and diving into the applications of AI! No prior coding experience is necessary!', classIndex: 47},
    {className: 'Hackathons 101: Everything You Need To Know', time: '12:30pm-1:20pm', room: 'Zoom', capacity: 40, description: 'No description.', classIndex: 48},
    {className: 'Inside the Mind of a Hacker', time: '12:30pm-1:20pm', room: 'Zoom', capacity: 40, description: 'Are you interested in hacking? Do you see news stories about the latest breach and wonder how they did it? In this class, we\'ll introduce you to the world of cybersecurity, explore the adversarial mindset through some activities, and discuss different career paths in the field. By the end, you\'ll have a clearer picture of how hackers think, how professionals defend against them, and next steps for continuing your hacking journey!', classIndex: 49},
    {className: 'Creating a website with GitHub pages and Jekyll', time: '12:30pm-1:20pm', room: 'Zoom', capacity: 20, description: 'Creating a Website using GitHub pages and Jekyll: A hands-on beginner friendly session to help you get started with creating and publishing a website for free using GitHub pages. GitHub pages is an ideal solution for creating a simple project, a blog, or a personal site to highlight your academic work. Along the way we will cover the basics of GitHub, HTML, Markdown, and Jekyll.', classIndex: 50},
    
    
    {className: 'Careers in CS', time: '1:30pm-2:20pm', room: 'Zoom', capacity: 30, description: 'Explore the diverse and exciting opportunities within the tech industry. In this course, students will learn about various computer science careers—from software engineering and data science to cybersecurity and game development—and gain insights into the skills and education needed to thrive in each field.', classIndex: 51},
    {className: 'Motion Planning for Robotics and More', time: '1:30pm-2:20pm', room: 'Zoom', capacity: 30, description: 'Discover the core principles and current research that enable robots to navigate from point A to point B through complex environments. Develop your own algorithms for motion planning. Learn about other applications of motion planning: Google Maps, video games, and more!', classIndex: 52},
    {className: 'Python, Pandas, and Predictions', time: '1:30pm-2:20pm', room: 'Zoom', capacity: 25, description: 'Dive into the world of data science at UIUC by exploring Python, Pandas, and AI/ML through real-time coding in Google Colab. Analyze real datasets and even build a machine learning model. No prior programming experience or software installation required :)', classIndex: 53},
    {className: 'Build Your Own Keylogger', time: '1:30pm-2:20pm', room: 'Zoom', capacity: 30, description: 'Learn about cybersecurity, spyware, and keyloggers, which are programs designed to monitor and record a user\'s keystrokes. In this hands-on class, we will also build a keylogger using Python. Some programming experience is recommended.', classIndex: 54},
    {className: 'Looking Forward, Planning Ahead', time: '1:30pm-2:20pm', room: 'Zoom', capacity: 40, description: 'We want to talk today about some of the co-curricular or non-academic experiences you can explore to develop personally and professionally during your time at UIUC. Making the most of your time may require that you set goals and make plans. As such, we talk about the importance of planning ahead and setting goals to make the most of your time at college, and how to go about making such plans!', classIndex: 56},
    {className: 'Software to Silicon: How Your Code Actually Works', time: '1:30pm-2:20pm', room: 'Zoom', capacity: 40, description: 'Explore the journey of code from high-level languages down to the physics of computation. You\'ll learn the basics of compilers, assembly, microarchitecture, and transistors, gaining a deeper appreciation for the hidden complexity behind every program you write. Whether you\'re a CS or engineering student, this course will give you the foundational knowledge to write better code and understand the machines running it.', classIndex: 57},
    {className: 'What\'s the Plot?', time: '1:30pm-2:20pm', room: 'Zoom', capacity: 20, description: 'Lights, camera… data! This beginner-friendly course is your ticket to the world of data science, using movie data to make learning both engaging and practical. With Python, NumPy, and Matplotlib, you\'ll analyze popularity trends, explore genre patterns, and visualize audience ratings—all while building a strong foundation in data analysis. By the final scene, you\'ll be equipped with essential skills to uncover insights from data and tell compelling stories with numbers. No prior experience needed—just bring your curiosity!', classIndex: 58},
    {className: 'Intro to Game Development in Roblox Studio', time: '1:30pm-2:20pm', room: 'Zoom', capacity: 40, description: 'Welcome to Intro to Game Development! In this course, we\'ll be going over the ins and outs of developing a game using the Roblox Studio game engine. Knowledge of Roblox Studio is not required and the course will focus more on general concepts that can apply to any game engine whether it be Unity, Unreal or Godot. By the end of the class, you will be able to enjoy your own fully working experience. Assumes basic programming knowledge.', classIndex: 59},
    {className: 'Introduction to Computer Architecture', time: '1:30pm-2:20pm', room: 'Zoom', capacity: 40, description: 'Discover the fundamentals of computer architecture through an interactive deep dive into flip-flops, finite state machines, arithmetic machine dataflow, MIPS Assembly, and Verilog. See how these concepts shape real-world systems, from processors to embedded devices!', classIndex: 60}
  ];  

// {className: 'Applying to Internships 101', time: '10:00am-10:50am', room: 'Siebel 1404', capacity: 200, description: 'Description coming soon!', classIndex: 6},
// {className: 'LeetCode for Beginners', time: '10:00am-10:50am', room: 'Siebel 220', capacity: 48, description: 'Description coming soon!', classIndex: 2},
// {className: 'Intro to Hackathons or Hackathon 101', time: '1:30pm-2:20pm', room: 'Zoom', capacity: 30, description: 'Hackathons are fast-paced competitions where programmers, designers, and innovators collaborate to build creative solutions under time constraints. We\'ll explore what hackathons are, why they\'re valuable for learning and career growth, and how they connect to real-world computer science. We\'ll break down key strategies for winning, from forming strong teams to pitching ideas effectively. Whether you\'re new to coding or already experienced, this session will equip you with the mindset and skills to thrive in hackathons and beyond.', classIndex: 55},
// {className: 'Intro to Hackathons or Hackathon 101', time: '2:00pm-2:50pm', room: 'Siebel 4407', capacity: 20, description: 'Hackathons are fast-paced competitions where programmers, designers, and innovators collaborate to build creative solutions under time constraints. We\'ll explore what hackathons are, why they\'re valuable for learning and career growth, and how they connect to real-world computer science. We\'ll break down key strategies for winning, from forming strong teams to pitching ideas effectively. Whether you\'re new to coding or already experienced, this session will equip you with the mindset and skills to thrive in hackathons and beyond.', classIndex: 67},


// if class has no zoom link, set as null
allClasses.forEach((c, i) => {
    if (!c.zoomLink) {
        c.zoomLink = null;
    }
});

allClasses.forEach((c, i) => c.index = i);

const inPersonMorningClassesFirst = allClasses.filter(c => c.time === '10:00am-10:50am');
const inPersonMorningClassesSecond = allClasses.filter(c => c.time === '11:00am-11:50am');
const inPersonAfternoonClasses = allClasses.filter(c => c.time === '2:00pm-2:50pm');
const virtualMorningClasses = allClasses.filter(c => c.time === '12:30pm-1:20pm');
const virtualAfternoonClasses = allClasses.filter(c => c.time === '1:30pm-2:20pm');

export { inPersonMorningClassesFirst, inPersonMorningClassesSecond, inPersonAfternoonClasses, virtualMorningClasses, virtualAfternoonClasses};
export default allClasses;